import React, { createRef, useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import { navigate } from "gatsby";
import { toast } from "react-toastify";
import { toBlob } from "html-to-image";
import saveAs from "file-saver";

import { Container, GridContainer } from "@util/standard";
import { AppLayout, AdvertBoard } from "@shared/salesTool";
import { MenuContentContainer, MenuWrapper, RndStyle } from "@shared/salesTool/components.styles";
import { appPaths } from "@util/constants";
import { useStore } from "@state/store";
import { useImagesFromSanity } from "@util/hooks";
import { Button } from "@global";
import { isBrowser } from "@util/helper";
import { useConvertElementsToZipFile } from "@util/firebaseHooks";

const NO_AD_COPY_ERROR = "No Ad copy found. Please create your Ad copy in the Editor.";

export default function Preview() {
  if (!isBrowser()) return null;
  // state
  const [currentIndex, setCurrentIndex] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [currentPreviewImage, setCurrentPreviewImage] = useState<string>();
  const [blobLoading, setBlobLoading] = useState(false);
  const [isGrayscale, setIsGrayscale] = useState(false);

  //hooks
  const [previewImages, calendarTitles] = useImagesFromSanity();
  const [zipLoading, _hasSent, convertToZip] = useConvertElementsToZipFile();
  const { adCopy, getOrderZipName } = useStore();

  const fileName = getOrderZipName();
  const advertBoardRef = createRef<HTMLDivElement>();

  //side effects
  useEffect(() => {
    if (adCopy == null) {
      toast.error(NO_AD_COPY_ERROR);
    }
  }, []);

  useEffect(() => {
    if (previewImages) {
      const image = previewImages[currentIndex];
      setCurrentPreviewImage(image);
    }
  }, [previewImages, currentIndex]);

  // functions
  const handleSaveToOrder = () => {
    if (adCopy == null) {
      toast.error(NO_AD_COPY_ERROR);
      return;
    }
    navigate(appPaths.editOrder);
  };

  const handleCycleThroughImages = (next: boolean) => () => {
    if (previewImages == null) return;
    if (next) {
      if (currentIndex === previewImages?.length - 1) {
        setCurrentIndex(0);
        return;
      }
      setCurrentIndex(prev => prev + 1);
      return;
    }
    if (currentIndex === 0) {
      setCurrentIndex(previewImages?.length - 1);
      return;
    }
    setCurrentIndex(prev => prev - 1);
  };

  const handlePreviewImage = () => {
    if (adCopy?.copy == null || advertBoardRef.current == null) return;
    setBlobLoading(true);
    toBlob(advertBoardRef.current, { cacheBust: true, pixelRatio: 2 }).then(blob => {
      if (blob == null) {
        toast.error("Error downloading preview");
        return;
      }
      saveAs(blob, `preview-${fileName}`);
      setBlobLoading(false);
    });
  };

  return (
    <AppLayout hideFooter currentPath="preview" onRightButtonClick={handleSaveToOrder}>
      <Container>
        <MenuWrapper>
          <MenuContentContainer>
            <Container
              flexDirection="column"
              width="80%"
              margin="60px auto 110px auto"
              justifyContent="space-between"
            >
              <div>
                <h2>Calendar Preview</h2>
                <p>
                  Preview the finished Ad Copy on the calendar by resizing and/or dragging the Ad
                  Copy space on the calendar.
                </p>
                {adCopy && (
                  <Container margin="30px 0 0 0" flexDirection="column" rowGap={15}>
                    {/* <Button linkText="Add to order" onClick={handleSaveToOrder} /> */}
                    <Button
                      loading={blobLoading}
                      linkText="Download Preview"
                      onClick={handlePreviewImage}
                    />
                    <Button
                      loading={zipLoading}
                      linkText="Download zip"
                      onClick={() => convertToZip(undefined, true)}
                    />
                    <Button
                      loading={zipLoading}
                      linkText={`Convert to ${isGrayscale ? "colour" : "greyscale"}`}
                      onClick={() => setIsGrayscale(!isGrayscale)}
                    />
                  </Container>
                )}
              </div>

              {previewImages && previewImages.length > 1 && (
                <div>
                  <h3>Switch between calendars</h3>
                  {calendarTitles && calendarTitles.length && <p>{calendarTitles[currentIndex]}</p>}
                  <GridContainer repeat={2} columnGap={20}>
                    <Button
                      linkText="< Previous"
                      width="100%"
                      onClick={handleCycleThroughImages(false)}
                    />
                    <Button
                      linkText="Next >"
                      width="100%"
                      onClick={handleCycleThroughImages(true)}
                    />
                  </GridContainer>
                </div>
              )}
            </Container>
          </MenuContentContainer>
        </MenuWrapper>

        <AdvertBoard
          ref={advertBoardRef}
          zoomState={[zoom, setZoom]}
          boardType="calendar"
          backgroundUrl={currentPreviewImage}
          backgroundContain
        >
          <AdCopy isGrayscale={isGrayscale} />
        </AdvertBoard>
      </Container>
    </AppLayout>
  );
}

interface AdCopyProps {
  isGrayscale?: boolean;
}

const AdCopy = ({ isGrayscale }: AdCopyProps) => {
  const { getProductAvertSize, adCopy } = useStore();

  if (adCopy == null) return null;
  const [selected, setSelected] = useState(true);
  const advertSize = getProductAvertSize();

  return (
    <Rnd
      lockAspectRatio
      style={{ zIndex: 1, filter: `grayscale(${isGrayscale ? "1" : "0"})` }}
      default={{
        x: 10,
        y: 10,
        width: `${advertSize?.width}px` ?? "auto",
        height: `${advertSize?.height}px` ?? "auto",
      }}
      resizeHandleStyles={selected ? RndStyle() : undefined}
      onMouseDown={() => setSelected(!selected)}
    >
      <img
        src={adCopy.copy}
        style={{
          height: "100%",
          width: "100%",
          pointerEvents: "none",
          userSelect: "none",
        }}
      />
    </Rnd>
  );
};
